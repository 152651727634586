import React from "react";
import ContactHeader from "components/Contact-header/contact-header";
import ContactForm from "components/Contact-form/contact-form";

const Contact = () => {

  return (
    <>

      <ContactHeader />
      <div className="main-content">
        <ContactForm />
        {/*  <div className="map" id="ieatmaps">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6010.126439563913!2d-100.75478744312291!3d41.13314584948978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2z2KfZhNmI2YTYp9mK2KfYqiDYp9mE2YXYqtit2K_YqQ!5e0!3m2!1sar!2seg!4v1644259110384!5m2!1sar!2seg"
            loading="lazy"
          ></iframe>
        </div> */}

        {/*   <Footer hideBGCOLOR /> */}
      </div>
    </>
  );
};

export const Head = () => {
  return (
    <>
      <title>Contact</title>
    </>
  )
}

export default Contact;
